.logo {
    float: left;
    width: 200px;
    height: 31px;
    color: white;
    font-weight: 700;
    font-size: 20px;
  }

  .logo a{
    color: white;
  }
  
  .ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  
  .site-layout-background {
    background: #fff;
  }

@primary-color: #3282B8;